import React from 'react';

export default function Message(){

    return (
        <section className="message">
            <div className="container">
                <h3>Feeding Kids in Our Own Backyard</h3>
                <p>Our mission is to ensure that every child in foster care and food insecure homes in Oregon and SW Washington has the nutrition they need to thrive.</p>
            </div>
        </section>
    );
   
}