import React from 'react';

export default function Footer(){

    return (
        <footer>
            <div className="container">
                <p className="legal">We are a 501(c)(3) charitable organization, EIN 81-4284286. All donations are 100% tax deductible with 100% of all donations going directly to helping families.</p>
                <p className="copywrite">© 2020 Copyright Our Giving Table.</p>
                </div>
        </footer>
    );
   
}