import React from 'react';

export default function Org(){

    return (
        <section className="org">
            <div className="container">
                <div className="left">
                    <div className="givingimg"></div>
                    <div className="content">
                        <h3>OUR GIVING TABLE</h3>
                        <p>Our Giving Table is a local nonprofit that donates to food insecure families. and children in Oregon and the SW Washington/Vancouver Metro Area.</p>
                        <a href="https://www.ourgivingtable.com" target="_blank">FIND OUT MORE</a>
                    </div>
                </div>
                <div className="right">
                    <div className="givingimg"></div>
                    <div className="content">
                        <h3><span>COMMUNITY PARTNER</span><br />PAPA MURPHY'S</h3>
                        <p>The 86 metro area Papa Murphy’s Restaurants are owned by almost 30 small business owners.  Together, they want to tackle hunger in our community.
                        </p>
                    </div>    
                </div>
            </div>
        </section>
    );
   
}