import React from 'react';

export default function Action(){

    return (
        <section className="partners">
            <div className="container">
                <h3>PARTNERS</h3>
                <p>Our Giving Table is thankful for our generous corporate sponsors who are helping food insecure families each day in our community.  Their generosity and investment in our youth allows us to provide free services that support over 1000+ kids in our local communities each week.</p>
                <div>
                    <div className="papaMurphys"></div>
                    <div className="theorem"></div>
                    <div className="kgwtv"></div>
                    <div className="iheartmedia"></div>
                </div>
            </div>
        </section>
    );
   
}