import React from 'react';

// import Nav from './components/nav';
import Hero from './components/hero';
import Message from './components/message';
import Organization from './components/org';
import Action from './components/action';
// import Subscribe from './components/subscribe';
import Footer from './components/footer';

function App() {
  return (
    <React.Fragment>

      <Hero />
      <Message />
      <Organization/>
      <Action/>
      <Footer />

    </React.Fragment>
  );
}

export default App;
