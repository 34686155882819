import React from "react";

export default function Hero() {
  return (
    <section className="hero">
      <div className="logo-container">
        <div className="logo"></div>
      </div>
      <div className="container">
        <h4>Helping Hungry Kids</h4>
        <h1>Starts With You</h1>
        <a href="https://donorbox.org/ourgivingtable">DONATE</a>
      </div>
    </section>
  );
}
